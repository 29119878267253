const hasOwnProperty = Object.prototype.hasOwnProperty;
 function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
}
// 同时匹配以 {} 和 {{}} 包裹的字符串，并且内部只包含指定字符
const RE_NARGS = /(%|)\{([0-9a-zA-Z_]+)\}|{{([0-9a-zA-Z_]+)}}/g;

/**
 *  String format template
 *  - Inspired:
 *    https://github.com/Matt-Esch/string-template/index.js
 */
export default function() {

  /**
   * template
   *
   * @param {String} string
   * @param {Array} ...args
   * @return {String}
   */

  function template(string, ...args) {

    string = string ||''
    if (args.length === 1 && typeof args[0] === 'object') {
      args = args[0];
    }

    if (!args || !args.hasOwnProperty) {
      args = {};
    }
    return string.replace(RE_NARGS, (match, prefix, i, j, index) => {
      let result;
      if (string[index - 1] === "{" && string[index + match.length] === "}") {
        return i;
      } else if (j) { 
        result = hasOwn(args, j) ? args[j] : null;
        if (result === null || result === undefined) {
          return "";
        }
        return result;
      } else {
        result = hasOwn(args, i) ? args[i] : null;
        if (result === null || result === undefined) {
          return "";
        }
        return result;
      }
    });
  }

  return template;
}
