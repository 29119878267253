/**
 * 拼接货币展示模板
 * 根据后端返回的货币结构split，拼接输出html模板
 * @param {*} split 货币展示结构，包括金额 number，货币符号 symbol，符号展示位置 position
 * @param {*} classSymbol 货币符号span class名
 * @param {*} classNumber 金额span class名
 * @return <span class="symbol">$</span><span class="number">100</span>
 */
export function amountTemplate (data) {
  let resTemplate = ''
  if (data) {
    const { split, clsSymbol, clsNumber } = data
    if (split) {
      const { number, symbol, position } = split // position 货币符号位置 0 货币符号在前 1 货币符号在后
      const symbolFirst = `<span class="${clsSymbol}">${symbol}</span><span class="${clsNumber}">${number}</span>`
      const numberFirst = `<span class="${clsNumber}">${number}</span><span class="${clsSymbol}">${symbol}</span>`
      resTemplate = +position === 0 ? symbolFirst : numberFirst
    }
  }
  return resTemplate
}

export function formatCoupon (coupon) {
  return {
    ...coupon,
    name: coupon.batch_name||coupon.name, // 券名称
    customerLogo:coupon.customerLogo || coupon.rlCustomLogo, //券logo
    availableStatus: coupon.availableStatus||(+coupon.status === 2 && +coupon.isExpired === 0 ? 1 : 2), // status 券状态 见wiki: http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=104837020; rl_expired 代表券是否过期 1:已过期 0:未过期
    minCost: coupon.minCost || 0, // 新版优惠券新增 最小使用金额
    minCostSplit: coupon.minCostSplit || {}, // 新版优惠券新增 最小使用金额
    expireTime: coupon.expireTimeFmt || coupon.expireTime, // 新版优惠券新增，过期时间。已精确为当地时间
    maxAmount: coupon.maxAmount ||0, // 新版优惠券新增，折扣券展示用，折扣券最高可抵扣金额
    maxAmountSplit: coupon.maxAmountSplit || {}, // 新版优惠券新增，折扣券展示用，折扣券最高可抵扣金额
    useRuleCity: coupon.useRuleCity || [], // 新版优惠券新增，城市维度使用限制
    useRuleWeekDay: coupon.useRuleWeekDay || [], // 新版优惠券新增，星期维度使用限制
    useRuleTimeRange: coupon.useRuleTimeRange || [], // 新版优惠券新增，券使用时间限制。精确到时分
    useRuleDate: coupon.useRuleDate || [], // 新版优惠券新增，券使用日期限制
    payTypeList: coupon.payTypeList || [], // 新版优惠券新增，支付方式列表
    costType: coupon.costType || 0, // 新版优惠券新增，抵扣类型
    shopNameList: coupon.shopNameList || [], // 新版优惠券新增，可使用店铺列表
    cardBinList: coupon.cardBinList || [],
    buttons:coupon.buttons || []
  }
}

export function debounce (func, wait, immediate) {
  let timeout
  let result
  const later = function (context, args) {
    timeout = null
    if (args) {
      result = func.apply(context, args)
    }
  }
  const debounced = function (...args) {
    if (timeout) {
      clearTimeout(timeout)
    }
    if (immediate) {
      const callNow = !timeout
      timeout = setTimeout(later, wait)
      if (callNow) {
        result = func.apply(this, args)
      }
    } else {
      timeout = setTimeout(() => {
        later(this, args)
      }, wait)
    }
    return result
  }
  debounced.cancel = function () {
    clearTimeout(timeout)
    timeout = null
  }
  return debounced
}