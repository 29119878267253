export default{
  data () {
    return {
      Rule: {
        useRuleWeekDay: {
          1: this.t('coupon.useRuleWeekDay.Monday'),
          2: this.t('coupon.useRuleWeekDay.Tuesday'),
          3: this.t('coupon.useRuleWeekDay.Wednesday'),
          4: this.t('coupon.useRuleWeekDay.Thursday'),
          5: this.t('coupon.useRuleWeekDay.Friday'),
          6: this.t('coupon.useRuleWeekDay.Saturday'),
          7: this.t('coupon.useRuleWeekDay.Sunday')
        },
        payTypeList: {
          1: this.t('coupon.payTypeList.card'),
          2: this.t('coupon.payTypeList.money'),
          3: this.t('coupon.payTypeList.all'),
          4: this.t('coupon.payTypeList.pos'),
          5: this.t('coupon.payTypeList.pay'),
          6: 'PayPay'
        },
        costType: {
          1: this.t('coupon.costType.order'),
          2: this.t('coupon.costType.meals'),
          3: this.t('coupon.costType.delivery')
        }
      }
    }
  }
}