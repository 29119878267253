import SodaCouponCell from './coupon-cell/index.vue'
import SodaCouponList from './coupon-list/index.vue'
import SodaCheckCell from './check-cell/index.vue'
import locale from '../locale'

const components = [
  SodaCouponCell,
  SodaCouponList,
  SodaCheckCell
]

const install = function(Vue, opts = {}) {
  locale.use(opts.locale);
  locale.i18n(opts.i18n);
  if (install.installed) return
  components.map(component => {
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  install,
  locale,
  SodaCouponCell,
  SodaCouponList,
  SodaCheckCell
}