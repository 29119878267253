export default {
  "FoodC_419_gQWM_QlQW": "部分商家可用",
  "FoodC_419_Gvte_qlpC": "全时段都可用",
  "FoodC_419_zBRR_jLNF": "没有门槛限制",
  "FoodC_419_gCIM_nQek": "所有支付方式可用",
  "FoodC_419_Bzsw_VoVQ": "全部服务城市可用",
  "FoodC_419_NGfm_Ulry": "全部商家可用",
  "coupon": {
    "other": {
      "time": "有效期至{name}",
      "hide": "隐藏",
      "cost": "可抵扣{name} ",
      "min": "满{{name}}可用",
      "show": "更多详情",
      "cardBin": "仅限支付卡bin为{name}可用",
      "limit": "仅限{name}",
      "new": "仅限新用户使用",
      "max": "最高抵扣{{name}}",
      "off": "OFF",
      "limitUse": "仅限{name}使用",
      "use": "{name}可用"
    },
    "costType": {
      "order": "订单",
      "delivery": "配送费",
      "meals": "餐费"
    },
    "useRuleWeekDay": {
      "Monday": "周一",
      "Thursday": "周四",
      "Saturday": "周六",
      "Tuesday": "周二",
      "Sunday": "周日",
      "Friday": "周五",
      "Wednesday": "周三"
    },
    "payTypeList": {
      "all": "通用支付",
      "card": "卡",
      "pay": "99Pay-余额",
      "pos": "POS",
      "money": "现金"
    }
  }
}